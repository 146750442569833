import React from 'react';
import { Box, Flex, Text, UnorderedList, ListItem } from '@chakra-ui/react';
import TypeSelector from './TypeSelector';
import PropTypes from 'prop-types';

const getStatusColor = status => {
  switch (status.toLowerCase()) {
    case 'approved':
      return '#4CAF50';
    case 're-instated':
      return '#4CAF50';
    case 'delisted':
      return '#FF4D49';
    case 'rejected':
      return '#FF4D49';
    default:
      return '#8B8B8B';
  }
};

const DetailsHeader = ({ packerName, estDetail }) => {
  return (
    <Box>
      <Flex alignItems="center" marginBottom="7px">
        <Text
          color="#171717"
          fontFamily="Poppins"
          fontSize="24px"
          fontStyle="normal"
          fontWeight="700"
          marginRight="15px"
        >
          {estDetail?.name}
        </Text>
        <Flex alignItems="center" gap={3} css={{ gap: '10px' }}>
          {!!estDetail?.status && (
            <Text
              as="span"
              fontSize="11px"
              fontWeight="500"
              letterSpacing="0.4px"
              padding="4px 12px"
              borderRadius="6px"
              background="#EFEFEF"
              color={getStatusColor(estDetail?.status || '')}
              textTransform="capitalize"
              opacity={estDetail?.listed_status === 'Delisted' ? '0.5' : '1'}
              {...(estDetail?.status === 'Rejected' && { title: `Reason: ${estDetail?.reason}` })}
            >
              {estDetail?.status}
            </Text>
          )}
          {estDetail?.listed_status && (
            <Text
              as="span"
              fontSize="11px"
              fontWeight="500"
              letterSpacing="0.4px"
              padding="4px 12px"
              borderRadius="6px"
              background="#EFEFEF"
              color={getStatusColor(estDetail?.listed_status || '')}
              textTransform="capitalize"
            >
              {estDetail?.listed_status}
            </Text>
          )}
        </Flex>
      </Flex>
      <UnorderedList
        display="flex"
        flexWrap="wrap"
        gap="25px"
        css={{ gap: '25px' }}
        fontSize="14px"
        marginBottom="10px"
      >
        <ListItem>
          <Text as="b" color="#000">
            Packer.:
          </Text>
          <Text as="span" marginLeft="4px" color="#000000B2">
            {packerName}
          </Text>
        </ListItem>
        <ListItem>
          <Text as="b" color="#000">
            Establishment No.:
          </Text>
          <Text as="span" marginLeft="4px" color="#000000B2">
            {estDetail?.est}
          </Text>
        </ListItem>
        <ListItem>
          <Text as="b" color="#000">
            Submitted By.:
          </Text>
          <Text as="span" marginLeft="4px" color="#000000B2">
            {estDetail?.first_name} {estDetail?.last_name}
          </Text>
        </ListItem>
      </UnorderedList>
      <TypeSelector types={estDetail?.est_supported_options} establishmentId={estDetail?.id} listedStatus={estDetail?.listed_status} status={estDetail?.status}/>
    </Box>
  );
};

DetailsHeader.propTypes = {
  packerName: PropTypes.string,
  estDetail: PropTypes.shape({
    name: PropTypes.string,
    status: PropTypes.string,
    reason: PropTypes.string,
    listed_status: PropTypes.string,
    est: PropTypes.string,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    est_supported_options: PropTypes.arrayOf(PropTypes.string),
    id: PropTypes.string,
  }),
};

export default DetailsHeader;
