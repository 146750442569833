import React from 'react';
import { Box, Flex } from '@chakra-ui/react';
import ContactInfo from './ContactInfo';
import ExternalAudit from './ExternalAudit';
import FacilityInfo from './FacilityInfo';
import Other from './Other';
import DetailsHeader from './DetailsHeader';
import ChangeStatus from './ChangeStatus';
import History from './History';
import PropTypes from 'prop-types';

const EstablishmentDetails = ({ packer, estDetail }) => {
  const externalAudiLinks = estDetail?.audit_other_links
    ? estDetail?.audit_other_links?.filter(item => item?.link_type === 'external_audit')
    : [];
  const otherLinks = estDetail?.audit_other_links
    ? estDetail?.audit_other_links?.filter(item => item?.link_type === 'other')
    : [];

  return (
    <>
      <Flex
        borderRadius="4px"
        background="#FBFBFB"
        padding="10px 20px 15px"
        justifyContent="space-between"
        alignItems="center"
        marginBottom="15px"
        flexWrap="wrap"
        style={{ gap: '10px' }}
      >
        <DetailsHeader packerName={packer?.name || ''} estDetail={estDetail} />
        <ChangeStatus establishmentId={estDetail?.id} estDetail={estDetail} />
      </Flex>
      <Box maxHeight="100%">
        <FacilityInfo data={estDetail?.facility_information} addressData={estDetail?.address} />
        <ExternalAudit data={externalAudiLinks} />
        <Other data={otherLinks} />
        <ContactInfo data={estDetail?.contacts_info} />
        <History establishmentId={estDetail?.id} />
      </Box>
    </>
  );
};

EstablishmentDetails.propTypes = {
  packer: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }),
  estDetail: PropTypes.shape({
    id: PropTypes.string,
    address:
      PropTypes.shape({
        line1: PropTypes.string,
        line2: PropTypes.string,
        city: PropTypes.string,
        state: PropTypes.string,
        country: PropTypes.string,
        post_code: PropTypes.string,
      }) || null,
    facility_information:
      PropTypes.shape({
        facility_type: PropTypes.string,
        facility_name: PropTypes.string,
        facility_address: PropTypes.string,
        facility_city: PropTypes.string,
        facility_state: PropTypes.string,
        facility_zip: PropTypes.string,
        facility_country: PropTypes.string,
        facility_phone: PropTypes.string,
        facility_email: PropTypes.string,
        facility_website: PropTypes.string,
      }) || null,
    contacts_info:
      PropTypes.arrayOf(
        PropTypes.shape({
          info_type: PropTypes.string,
          fullname: PropTypes.string,
          mobile: PropTypes.string,
          email: PropTypes.string,
        })
      ) || null,
    audit_other_links:
      PropTypes.arrayOf(
        PropTypes.shape({
          link_type: PropTypes.string,
          link: PropTypes.string,
        })
      ) || null,
  }),
};

export default EstablishmentDetails;
