import { CalendarIcon, ChevronDownIcon, SearchIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Checkbox,
  CheckboxGroup,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Heading,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Menu,
  MenuButton,
  MenuList,
  Select,
  Stack,
  Td,
  Text,
  Tr,
  Wrap,
} from '@chakra-ui/react';
import get from 'lodash/get';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Datetime from 'react-datetime';
import { connect } from 'react-redux';
import Constants from '../../Constants';
import { fetchConfigs } from '../../actions/actions_config';
import { clearOrders, fetchOrderHistory } from '../../actions/actions_orders';
import { fetchPackageWeights } from '../../actions/actions_package_weights';
import { fetchSpecifications } from '../../actions/actions_specifications';
import { fetchBuyerOrgUsers } from '../../actions/actions_users';
import { ReactComponent as ShareOutlineSVG } from '../../assets/share-outline.svg';
import RightAngleIcon from '../core/RightAngleIcon';
import OrderTable from '../../containers/orders/OrderTable';
import { commify, wrapDispatch } from '../../functions';
import { constructBuyerOptions } from '../../helpers';
import { buyersSelector } from '../../reducers/reducer_users';
import { generateAndDownloadExcel } from '../../services/excel-export';
import { fetchEntitiesData } from '../../slices/masterData/entityManagerSlice';
import { fetchOrderStatusMap, selectOrderStatusMap } from '../../slices/order-status-map/orderStatusMapSlice';
import { getInternalPoLabel } from '../../utils';
import SelectField from '../basic/SelectField';
import { PaginationBar } from '../core/PaginationComponents';
import './OrderHistory.scss';
import { convertCartonsToUnitOfMeasure } from './order-form/InternationalOrderLine/helpers';

const getQueryParam = (param, defaultValue = null) => new URLSearchParams(location.search).get(param) || defaultValue;

const CLEAN_FILTERS = {
  user_id: [],
  status: [],
  ordered_start_date: '',
  ordered_end_date: '',
  delivery_start_date: '',
  grinder_uids: null,
  grinder_po_number: getQueryParam('grinder_po_number', ''),
  delivery_end_date: '',
  packer_plant_ids: null,
  packer_ids: null,
  exclude_delisted_packer_plants: false,
  exclude_delisted_packers: false,
  partial_search: false,
  noEtd: false,
  buyer_id: null,
  po_lookup_field: window.location.href.includes('cfc')
    ? getQueryParam('po_lookup_field', 'internal_po_number')
    : getQueryParam('po_lookup_field', 'grinder_po_number'),
  internal_po_number: getQueryParam('internal_po_number', ''),
  packer_so_number: getQueryParam('packer_so_number', ''),
  booking_reference_number: '',
  missing_doc_type: null,
  shipmentPortMark: '',
  tags: [],
  specifications: [],
  etd_start_date: '',
  etd_end_date: '',
  eta_start_date: '',
  eta_end_date: '',
  orderShipmentTypes: [],
  product_types: '',
  isColdStoreRequired: null,
  end_user_id: null,
  container_number: '',
  packer_invoice_number: getQueryParam('packer_invoice_number', ''),
  bol_number: '',
  schema_type: 'order_history',
  sortColumns: 'order_date_DESC',
  page: 1,
  limit: 20,
};

const filtersSchema = {
  bol_number: {
    label: 'BOL #',
    type: 'text',
  },
  booking_reference_number: {
    label: 'Booking Reference #',
    type: 'text',
  },
  container_number: {
    label: 'Container Number',
    type: 'text',
  },
  shipmentPortMark: {
    label: 'Shipment Port Mark',
    type: 'text',
  },
  missing_doc_type: {
    label: 'Missing Document Type',
    type: 'select',
  },
  buyer_id: {
    label: 'Buyer',
    type: 'text',
  },
  delivery_end_date: {
    label: 'Delivery Date To',
    type: 'text',
  },
  delivery_start_date: {
    label: 'Delivery Date From',
    type: 'select',
  },
  end_user_id: {
    label: 'End User',
    type: 'select',
  },
  eta_end_date: {
    label: 'ETA To',
    type: 'select',
  },
  eta_start_date: {
    label: 'ETA From',
    type: 'select',
  },
  etd_end_date: {
    label: 'ETD To',
    type: 'select',
  },
  etd_start_date: {
    label: 'ETD From',
    type: 'select',
  },
  grinder_po_number: {
    label: 'Grinder PO #',
    type: 'select',
  },
  internal_po_number: {
    label: 'Internal PO #',
    type: 'select',
  },
  ordered_end_date: {
    label: 'Date Created To',
    type: 'select',
  },
  ordered_start_date: {
    label: 'Date Created From',
    type: 'select',
  },
  packer_ids: {
    label: 'Packer',
    type: 'select',
  },
  packer_plant_ids: {
    label: 'Establishment',
    type: 'select',
  },
  grinder_uids: {
    label: 'Grinder',
    type: 'select',
  },
  isColdStoreRequired: {
    label: 'Cold Store Required',
    type: 'select',
  },
  packer_so_number: {
    label: 'Packer SO #',
    type: 'select',
  },
  packer_invoice_number: {
    label: 'Packer Invoice #',
    type: 'select',
  },
  specifications: {
    label: 'Specification',
    type: 'select',
  },
  status: {
    label: 'Status',
    type: 'select',
  },
  tags: {
    label: 'Tags',
    type: 'select',
  },
  orderShipmentTypes: {
    label: 'Order Shipment Type',
    type: 'select',
  },
  product_types: {
    label: 'Product Type',
    type: 'select',
  },
};

const CheckboxGroupContainer = ({ children, ...rest }) => {
  return (
    <Wrap spacing="24px" {...rest.wrapStyle}>
      <CheckboxGroup colorScheme="actionSecondary" {...rest.checkBoxGroupStyle}>
        {children}
      </CheckboxGroup>
    </Wrap>
  );
};

CheckboxGroupContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

const OrderHistorySelectInternalSelect = props => {
  return (
    <SelectField
      styles={{
        control: styles => ({
          ...styles,
          minHeight: '40px',
        }),
        menu: styles => ({
          ...styles,
          zIndex: '10',
        }),
      }}
      isClearable
      {...props}
    />
  );
};

const OrderHistoryInput = props => {
  return <Input height="40px" {...props} />;
};
class OrderHistory extends Component {
  static propTypes = {
    dispatch: PropTypes.func,
    configs: PropTypes.arrayOf(PropTypes.object),
    specifications: PropTypes.arrayOf(PropTypes.object),
    token: PropTypes.string,
    apiRequestError: PropTypes.string,
    order_history: PropTypes.arrayOf(PropTypes.object),
    export_order_history: PropTypes.arrayOf(PropTypes.object),
    orderPagination: PropTypes.objectOf(PropTypes.string),
    tags: PropTypes.arrayOf(PropTypes.object),
    grinders: PropTypes.arrayOf(PropTypes.object),
    packer_plants: PropTypes.arrayOf(PropTypes.object),
    input_products: PropTypes.arrayOf(PropTypes.object),
    packageWeights: PropTypes.arrayOf(PropTypes.object),
    packers: PropTypes.arrayOf(PropTypes.object),
    end_users: PropTypes.arrayOf(PropTypes.object),
    users: PropTypes.arrayOf(PropTypes.object),
    user: PropTypes.shape({
      id: PropTypes.number,
      token: PropTypes.string,
      first_name: PropTypes.string,
      last_name: PropTypes.string,
      email: PropTypes.string,
    }).isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      currentOrder: false,
      multifilters: CLEAN_FILTERS,
      useFirstPageOneOnNextSearch: false,
      isExporting: false,
      showMoreFilters: false,
    };
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(clearOrders());
  }

  componentDidMount() {
    const { token, dispatch, configs, specifications } = this.props;
    dispatch(fetchEntitiesData());
    dispatch(fetchBuyerOrgUsers(token));
    dispatch(fetchPackageWeights());
    if (configs.length === 0) {
      dispatch(fetchConfigs(token));
    }
    if (specifications.length === 0) {
      dispatch(fetchSpecifications(token));
    }
    // Dispatch the fetchOrderStatusMap action
    dispatch(fetchOrderStatusMap());
    this.submitSearch();
  }

  componentDidUpdate(prevProps) {
    const { apiRequestError } = this.props;

    // reset loading state when request fails
    if (apiRequestError !== prevProps.apiRequestError) {
      this.setState({
        loading: false,
      });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.order_history.length ||
      (nextProps.order_history.length === 0 && this.props.order_history !== nextProps.order_history)
    ) {
      this.setState({ loading: false });
    }
  }

  setLoading = () => {
    this.setState({ loading: true });
  };

  updateFilter = (filterKey, filterValue) => {
    this.setState(prevState => ({
      multifilters: {
        ...prevState.multifilters,
        [filterKey]: filterValue,
      },
      useFirstPageOneOnNextSearch: true,
    }));
  };

  handleDateRangeFilterChange = (filterConfig, instasearch) => {
    if (instasearch) {
      return this.instasearchOrders(filterConfig);
    }
    return this.setState(prevState => ({
      multifilters: {
        ...prevState.multifilters,
        ...filterConfig,
      },
    }));
  };

  handlePageChange = filterConfig => {
    this.instasearchOrders({
      ...this.state.multifilters,
      ...filterConfig,
    });

    return this.setState(prevState => ({
      multifilters: {
        ...prevState.multifilters,
        ...filterConfig,
      },
    }));
  };

  handleFilterChange = (key, value, instasearch) => {
    const arrayFilters = ['status', 'user_id'];
    const booleanFilters = ['exclude_delisted_packers', 'noEtd', 'partial_search'];

    if (instasearch) {
      return this.instasearchOrders({ [key]: value });
    }
    if (value && value !== 'null') {
      const currentValue = get(this.state, `multifilters.${key}`);

      // These two keys can have multiple values, and are array types
      if (arrayFilters.includes(key)) {
        let updatedArray = [];
        // If status already has existing value, then remove it
        if (currentValue.includes(value)) {
          updatedArray = currentValue.filter(item => item !== value);
        } else {
          // Otherwise add it to an array
          updatedArray = [...currentValue, value];
        }
        return this.updateFilter(key, updatedArray);
      }
      // Otherwise, just add straight to object for checkbox fields in 'Other' category
      if (booleanFilters.includes(key)) {
        return this.updateFilter(key, !currentValue);
      }
      return this.updateFilter(key, value);
    }
    // If is null or undefined, then change value to blank
    return this.updateFilter(key, '');
  };

  instasearchOrders = searchConfig => {
    // Clears everything and then sets a simple one-thing search
    const updatedMultiFilters = {
      ...CLEAN_FILTERS,
      ...searchConfig,
    };

    this.setState(
      {
        multifilters: updatedMultiFilters,
        loading: true,
      },
      () => {
        this.props.dispatch(fetchOrderHistory(updatedMultiFilters, this.props.token));
      }
    );
  };

  onPackerStuffSelect = (fieldName, value) => {
    const { multifilters } = this.state;
    const fieldToClear = fieldName === 'packer_plant_ids' ? 'packer_ids' : 'packer_plant_ids';
    const newMultifilters = Object.assign({}, multifilters);
    newMultifilters[fieldName] = value;
    newMultifilters[fieldToClear] = null;

    this.setState({ multifilters: newMultifilters, useFirstPageOneOnNextSearch: true });
  };

  onBuyerSelect = value => {
    const { multifilters } = this.state;
    const newMultifilters = { ...multifilters, buyer_id: value || '' };
    this.setState({ multifilters: newMultifilters, useFirstPageOneOnNextSearch: true });
  };

  onEndUserSelect = value => {
    const { multifilters } = this.state;
    const newMultifilters = { ...multifilters, end_user_id: value || '' };
    this.setState({ multifilters: newMultifilters, useFirstPageOneOnNextSearch: true });
  };

  removeFilter = (key, value = null) => {
    const { multifilters } = this.state;
    const newMultifilters = { ...multifilters, [key]: value };
    this.setState({ multifilters: newMultifilters, useFirstPageOneOnNextSearch: true });
    setTimeout(this.submitSearch, 1);
  };

  getSelectedFilters = () => {
    const { multifilters } = this.state;
    const selectedFilters = [];
    const filterKeys = Object.keys(filtersSchema);

    filterKeys.forEach(key => {
      const value = multifilters[key];
      if (value && (Array.isArray(value) ? value.length : value)) {
        selectedFilters.push({ key, value, label: filtersSchema[key].label });
      }
    });

    return selectedFilters;
  };

  getLabelWithValueType = (key, value) => {
    let buyerUser;
    switch (key) {
      case 'buyer_id':
        buyerUser = this.props.users.find(user => user.id === value);
        return `${buyerUser?.first_name} ${buyerUser?.last_name}`;
      case 'end_user_id':
        return this.props.end_users.find(user => user.id === value)?.legal_name;
      case 'isColdStoreRequired':
        return value === 'true' ? 'Yes' : 'No';
      case 'grinder_uids':
        return this.props.grinders.find(grinder => grinder.uid === value)?.name;
      default:
        return value;
    }
  };

  submitSearch = e => {
    if (e) {
      e.preventDefault();
    }
    this.props.dispatch(fetchOrderHistory(this.prepareFilters(), this.props.token));
    this.setLoading();
  };

  prepareFilters = () => {
    const filters = Object.assign({}, this.state.multifilters, {
      ...(this.state.useFirstPageOneOnNextSearch && {
        page: 1,
      }),
    });
    return filters;
  };

  resetSearch = e => {
    if (e) {
      e.preventDefault();
    }

    const newState = JSON.parse(JSON.stringify(this.state));
    newState.multifilters = CLEAN_FILTERS;
    newState.useFirstPageOneOnNextSearch = false;
    this.setState(newState);
    // this.props.dispatch(resetPurchaseOrders());
    setTimeout(this.submitSearch, 1);
  };

  renderInput = (props, refName) => {
    return (
      <Box>
        <input
          {...props}
          ref={el => {
            this[refName] = el;
          }}
        />
        <InputRightElement style={{ width: 30, height: '100%' }}>
          <CalendarIcon style={{ height: '100%' }} color="tertiary.900" />
        </InputRightElement>
      </Box>
    );
  };

  selectDate = (e, name, ref) => {
    this.handleFilterChange(name, e._isAMomentObject ? e.format('YYYY-MM-DD') : e);

    if (ref) {
      ref.focus();
    }
  };

  getLineSpecifications = line => {
    return line.specifications;
  };

  exportOrders() {
    this.setState({
      isExporting: true,
    });
    wrapDispatch(this.props.dispatch, fetchOrderHistory(this.prepareFilters(), this.props.token, true)).then(() => {
      const {
        // order_history,
        packer_plants,
        grinders,
        input_products,
        packageWeights,
        export_order_history: exportOrderHistory,
      } = this.props;
      if (!exportOrderHistory) {
        this.setState({
          isExporting: false,
        });
        return;
      }
      const internalPOLabel = getInternalPoLabel(this.props.configs);
      const worksheetColumnConfig = [
        { 'Date Created': 'order.order_date' },
        { Grinder: 'grinder.name' },
        { 'Grinder PO#': 'order.grinder_po_number' },
        { [internalPOLabel]: 'order.internal_po_number' },
        { Establishment: 'packer_plant.name' },
        { 'Establishment ID': 'packer_plant.est' },
        { 'Packer SO#': 'order.packer_so_number' },
        { Status: 'line.status' },
        { IsActive: 'line.active' },
        { 'Delivery Date': 'order.delivery_date' },
        { Temp: 'line.temp' },
        { Product: 'line.input_product.name' },
        { 'Product Description': 'line.input_product.description' },
        { CL: 'line.input_product.cl' },
        { Description: 'line.description' },
        { Specification: 'line.specification' },
        { 'Quantity Buy': 'line.buy_quantity' },
        { 'Buy UOM': 'order.buy_unit_of_measure.name' },
        { 'Buy Price Per Unit': 'line.buy_price_per_unit' },
        { 'Buy Currency': 'order.buy_currency' },
        { 'Quantity Sell': 'line.sell_quantity' },
        { 'Sell UOM': 'order.sell_unit_of_measure.name' },
        { 'COS Price Per Unit': 'line.cos_price_per_unit' },
        { 'Sell Price Per Unit': 'line.sell_price_per_unit' },
        { 'Sell Currency': 'order.sell_currency' },
        { 'Received Quantity': 'line.recv_quantity' },
        { 'Remaining Weight': 'line.remaining_weight' },
        { 'Internal Comments': 'order.internal_comments' },
        { 'External Comments': 'order.external_comments' },
        { Container: 'order.load_container_details' },
        { 'Buy Incoterms': 'order.buy_incoterms' },
        { 'Sell Incoterms': 'order.sell_incoterms' },
        { Halal: 'order.halal' },
        { MTC: 'order.mtc' },
        { 'Delivery Time': 'order.scheduled_delivery_time' },
        { 'Transport Cost': 'order.transport_cost' },
        { 'Date Type': 'dateType.type' },
        { 'Shipment/Arrival Start Date': 'dateType.start_date' },
        { 'Shipment/arrival End Date': 'dateType.end_date' },
        { 'Preferred ETA': 'order.preferred_eta' },
        { 'Expected Production Date': 'line.expected_production_date' },
        { 'Final Destination': 'order.final_destination' },
        { 'Vessel Name': 'order.voyage[0].vessel_name' },
        { 'Voyage Number': 'order.voyage[0].voyage_number' },
        { 'Shipping Line': 'order.voyage[0].shipping_line.name' },
        { 'Port of Loading': 'order.voyage[0].load_port.name' },
        { ETD: 'order.voyage[0].etd' },
        { 'Port of Discharge': 'order.voyage[0].discharge_port.name' },
        { ETA: 'order.voyage[0].eta' },
        { 'Tranship Port': 'order.voyage[0].tranship_port.name' },
        { 'Booking Reference #': 'order.bill_of_lading.booking_reference_number' },
        { EDN: 'order.bill_of_lading.export_declaration_number' },
        { 'Container #': 'order.bill_of_lading.container_number' },
        { 'Container Temp': 'order.bill_of_lading.container_temperature' },
        { 'Seal #': 'order.bill_of_lading.seal_number' },
        { 'Bill of Lading #': 'order.bill_of_lading.bol_number' },
        { 'Health Certificate #': 'order.export_details.health_certificate_number' },
        { ISF: 'order.export_details.isf' },
        { 'Permit #': 'order.export_details.permit_number' },
        { 'Container Depot': 'order.export_details.container_depot' },
        { 'Container Return': 'order.export_details.container_return' },
        { 'Container Gate in': 'order.export_details.container_gate_in_date' },
        { 'Coldstore Name': 'coldstore_name' },
        { 'Gross Shipped Weight': 'order.bill_of_lading.gross_shipped_weight' },
        { 'Customs Cleared Date': 'order.coldstore_details.customed_cleared_date' },
        { 'Inspection Date': 'order.coldstore_details.inspection_date' },
      ];
      generateAndDownloadExcel('Purchase Orders', [
        {
          worksheetName: 'Purchase Orders',
          columnConfigs: worksheetColumnConfig.reduce((agg, config) => {
            const configKeyPair = Object.entries(config)[0];
            const headerText = configKeyPair[0];
            const dataMap = configKeyPair[1];
            return [
              ...agg,
              {
                headerText,
                getter: data => {
                  if (dataMap === 'order.order_date') {
                    const purchasingOffice = get(data, 'order.purchasing_office', 'Sydney');
                    const formatted = get(data, dataMap, null)
                      ? moment(get(data, dataMap))
                          .tz(purchasingOffice.toLowerCase() === 'sydney' ? 'Australia/Sydney' : 'America/Chicago')
                          .format('YYYY-MM-DD')
                      : '';
                    return formatted;
                  }
                  return get(data, dataMap, '');
                },
              },
            ];
          }, []),
          data: exportOrderHistory
            .map(order => {
              return order.lines.map(line => {
                return {
                  order,
                  line: {
                    ...line,
                    product: input_products.find(product => product.uid === line.input_product_uid),
                    description:
                      line.specifications.includes('fmg_angus') || line.specifications.includes('usda_angus')
                        ? 'Angus'
                        : 'Regular',
                    specification: this.getLineSpecifications(line),
                    temp: line.frozen ? 'Frozen' : line.chilled ? 'Chilled' : '',
                    status: line.active ? order.status : '',
                    remaining_weight: line.carton_count
                      ? convertCartonsToUnitOfMeasure(
                          line.carton_count,
                          get(order, 'sell_unit_of_measure.name'),
                          line.input_product_uid,
                          order.packer_plant_id,
                          order.purchasing_office,
                          packageWeights
                        )
                      : 0,
                  },
                  packer_plant: packer_plants.find(packer_plant => packer_plant.id === order.packer_plant_id),
                  grinder: grinders.find(grinder => grinder.uid === order.grinder_uid),
                  dateType: {
                    type: order.shipment ? 'Shipment' : order.arrival ? 'Arrival' : '',
                    start_date: order.shipment
                      ? order.shipment_date_start
                      : order.arrival
                      ? order.arrival_date_start
                      : '',
                    end_date: order.shipment ? order.shipment_date_end : order.arrival ? order.arrival_date_end : '',
                  },
                  coldstore_name: order?.coldstore_details?.cold_store?.company_name,
                };
              });
            })
            .flat(1),
        },
      ]);
      this.setState({
        isExporting: false,
      });
    });
  }

  render() {
    const { orderStatusMap } = this.props;
    const { loading, currentOrder, isExporting, multifilters, showMoreFilters } = this.state;
    const { tags, orderPagination, specifications } = this.props;
    if (currentOrder) {
      currentOrder.line_items.map(line_item => {
        let thisPacker = false;
        this.props.packer_plants.forEach(function (packer_plant) {
          if (packer_plant.id === line_item.packer_plant_id) {
            thisPacker = packer_plant;
          }
        });
        let thisInputProduct = false;
        this.props.input_products.forEach(function (input_product) {
          if (input_product.uid === line_item.input_product_uid) {
            thisInputProduct = input_product;
          }
        });
        return (
          <Tr key={`line-item-${line_item.uid}`} style={{ background: '#f3f4f8' }}>
            <Td />
            <Td>{thisPacker.name}</Td>
            <Td>{thisInputProduct.name}</Td>
            <Td>{commify(line_item.buy_quantity)}</Td>
            <Td />
            <Td>${line_item.ingredient_cost_per_unit}</Td>
            <Td>${line_item.transport_cost_per_unit}</Td>
            <Td>${line_item.all_in_cost_per_unit}</Td>
            <Td>${commify(line_item.all_in_cost)}</Td>
          </Tr>
        );
      });
    }

    const sortOptionsByLabel = (a, b) => {
      const x = a.label;
      const y = b.label;
      return x < y ? -1 : x > y ? 1 : 0;
    };
    const grinderOptions = (this.props.grinders || [])
      .map(item => ({ value: item.uid, label: item.name }))
      .sort(sortOptionsByLabel);

    const individualPackerOptions = (this.props.packer_plants || [])
      .map(item => ({ value: item.id, label: item.name }))
      .sort(sortOptionsByLabel);

    const packerOptions = (this.props.packers || [])
      .map(item => ({ value: item.id, label: item.name }))
      .sort(sortOptionsByLabel);

    const endUserOptions = (this.props.end_users || [])
      .filter(item => item.is_active)
      .map(item => ({ value: item.id, label: item.legal_name }))
      .sort(sortOptionsByLabel);

    const buyerOptions = constructBuyerOptions(buyersSelector(this.props.users), this.props.user).sort(
      sortOptionsByLabel
    );

    const labelOptions = tags && tags.map(l => ({ label: l.value, value: l.value })).sort(sortOptionsByLabel);
    const specificationOptions =
      specifications && specifications.map(l => ({ label: l.name, value: l.uid })).sort(sortOptionsByLabel);
    const startDateProps = {
      placeholder: 'From',
      className: 'form-control m-input',
    };
    const endDateProps = {
      placeholder: 'To',
      className: 'form-control m-input',
    };
    const internalPOLabel = getInternalPoLabel(this.props.configs);

    return (
      <form onSubmit={this.submitSearch}>
        <Box className="order-history" my="2em" mx="4em">
          <HStack spacing="10px" justifyContent="space-between" mb={2}>
            <HStack>
              <RightAngleIcon />
              <Heading className="dashboard-section__heading" fontSize="15px">
                Orders
              </Heading>
            </HStack>
            <HStack className="filters">
              <Box>
                <div className="po-filter">
                  <Select
                    width="147px"
                    value={multifilters.po_lookup_field}
                    onChange={e => {
                      this.handleFilterChange('po_lookup_field', get(e, 'target.value', null));
                      this.handleFilterChange('internal_po_number', '');
                      this.handleFilterChange('grinder_po_number', '');
                      this.handleFilterChange('packer_so_number', '');
                      this.handleFilterChange('packer_invoice_number', '');
                    }}
                  >
                    <option value="internal_po_number">{internalPOLabel}</option>
                    <option value="grinder_po_number">Grinder PO #</option>
                    <option value="packer_so_number">Packer SO #</option>
                    <option value="packer_invoice_number">Packer Invoice #</option>
                  </Select>
                  <InputGroup size="md">
                    <InputLeftElement pointerEvents="none">
                      <SearchIcon color="gray.300" />
                    </InputLeftElement>
                    <Input
                      name="po_number"
                      width="354px"
                      type="text"
                      placeholder="Search"
                      value={multifilters[multifilters.po_lookup_field] || ''}
                      onChange={e => {
                        this.handleFilterChange(multifilters.po_lookup_field, get(e, 'target.value', null));
                      }}
                    />
                  </InputGroup>
                </div>
              </Box>
              {/* STATUS */}
              <Box position="relative">
                <Menu closeOnSelect={false}>
                  <MenuButton
                    as={Button}
                    width="130px"
                    variant="outline"
                    colorScheme="gray.200"
                    color="gray.500"
                    borderColor="gray.200"
                    rightIcon={<ChevronDownIcon />}
                    display="flex"
                    alignItems="center"
                  >
                    <Box display="flex" alignItems="center">
                      <Box as="span" lineHeight="0">
                        Status
                      </Box>
                      {multifilters?.status?.length > 0 && (
                        <Box
                          as="span"
                          width="18px"
                          height="18px"
                          marginLeft="5px"
                          borderRadius="full"
                          bgColor="#3B44B0"
                          color="white"
                          fontSize="12px"
                          lineHeight="0"
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                        >
                          {multifilters?.status?.length}
                        </Box>
                      )}
                    </Box>
                  </MenuButton>
                  <MenuList>
                    <Stack p={6} spacing={4}>
                      {orderStatusMap &&
                        Object.keys(orderStatusMap)
                          .filter(
                            key => !['pending_coldstore_email', 'dispatched', 'invoiced', 'submitted'].includes(key)
                          )
                          .map(key => ({ value: key, label: orderStatusMap[key] }))
                          .sort(sortOptionsByLabel)
                          .map(({ value, label }) => (
                            <Checkbox
                              key={value}
                              isChecked={multifilters.status.indexOf(value) > -1}
                              onChange={() => this.handleFilterChange('status', value)}
                            >
                              {label}
                            </Checkbox>
                          ))}
                    </Stack>
                  </MenuList>
                </Menu>
              </Box>
              {/* DATE */}
              <Box>
                <Menu closeOnSelect={false}>
                  <MenuButton
                    as={Button}
                    variant="outline"
                    colorScheme="gray.200"
                    color="gray.500"
                    borderColor="gray.200"
                    width="130px"
                    rightIcon={<ChevronDownIcon />}
                  >
                    <Box display="flex" alignItems="center">
                      <Box as="span" lineHeight="0">
                        Date
                      </Box>
                      {(multifilters.ordered_start_date ||
                        multifilters.ordered_end_date ||
                        multifilters.delivery_start_date ||
                        multifilters.delivery_end_date ||
                        multifilters.etd_start_date ||
                        multifilters.etd_end_date ||
                        multifilters.eta_start_date ||
                        multifilters.eta_end_date) && (
                        <Box
                          as="span"
                          width="18px"
                          height="18px"
                          marginLeft="5px"
                          borderRadius="full"
                          bgColor="#3B44B0"
                          color="white"
                          fontSize="12px"
                          lineHeight="0"
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                        >
                          {
                            [
                              multifilters.ordered_start_date,
                              multifilters.ordered_end_date,
                              multifilters.delivery_start_date,
                              multifilters.delivery_end_date,
                              multifilters.etd_start_date,
                              multifilters.etd_end_date,
                              multifilters.eta_start_date,
                              multifilters.eta_end_date,
                            ].filter(Boolean).length
                          }
                        </Box>
                      )}
                    </Box>
                  </MenuButton>
                  <MenuList>
                    <Stack p={6} spacing={4}>
                      {/* DATE CREATED */}
                      <FormControl>
                        <FormLabel>Date Created</FormLabel>
                        <HStack>
                          <Datetime
                            id="ordered_start_date"
                            inputProps={startDateProps}
                            closeOnSelect
                            value={multifilters.ordered_start_date}
                            renderInput={props => this.renderInput(props, 'orderedStartRef')}
                            onChange={e => this.selectDate(e, 'ordered_start_date', this.orderedStartRef)}
                            timeFormat={false}
                            dateFormat="YYYY-MM-DD"
                          />
                          <Datetime
                            id="ordered_end_date"
                            inputProps={endDateProps}
                            closeOnSelect
                            value={multifilters.ordered_end_date}
                            renderInput={props => this.renderInput(props, 'orderedEndRef')}
                            onChange={e => this.selectDate(e, 'ordered_end_date', this.orderedEndRef)}
                            timeFormat={false}
                            dateFormat="YYYY-MM-DD"
                          />
                        </HStack>
                      </FormControl>
                      {/* DATE DELIVERY */}
                      <FormControl>
                        <FormLabel>Date Delivery</FormLabel>
                        <HStack>
                          <Datetime
                            id="delivery_start_date"
                            inputProps={startDateProps}
                            closeOnSelect
                            value={multifilters.delivery_start_date}
                            renderInput={props => this.renderInput(props, 'deliveryStartRef')}
                            onChange={e => this.selectDate(e, 'delivery_start_date', this.deliveryStartRef)}
                            timeFormat={false}
                            dateFormat="YYYY-MM-DD"
                          />
                          <Datetime
                            id="delivery_end_date"
                            inputProps={endDateProps}
                            closeOnSelect
                            value={multifilters.delivery_end_date}
                            renderInput={props => this.renderInput(props, 'deliveryEndRef')}
                            onChange={e => this.selectDate(e, 'delivery_end_date', this.deliveryEndRef)}
                            timeFormat={false}
                            dateFormat="YYYY-MM-DD"
                          />
                        </HStack>
                      </FormControl>
                      {/* ETD */}
                      <FormControl>
                        <FormLabel>ETD</FormLabel>
                        <HStack>
                          <Datetime
                            id="etd_start_date"
                            inputProps={startDateProps}
                            closeOnSelect
                            value={multifilters.etd_start_date}
                            renderInput={props => this.renderInput(props, 'etd_start_date')}
                            onChange={e => this.selectDate(e, 'etd_start_date', this.etd_start_date)}
                            timeFormat={false}
                            dateFormat="YYYY-MM-DD"
                          />
                          <Datetime
                            id="etd_end_date"
                            inputProps={endDateProps}
                            closeMenuOnSelect
                            value={multifilters.etd_end_date}
                            renderInput={props => this.renderInput(props, 'etd_end_date')}
                            onChange={e => this.selectDate(e, 'etd_end_date', this.etd_end_date)}
                            timeFormat={false}
                            dateFormat="YYYY-MM-DD"
                          />
                        </HStack>
                      </FormControl>
                      {/* ETA */}
                      <FormControl>
                        <FormLabel>ETA</FormLabel>
                        <HStack>
                          <Datetime
                            id="eta_start_date"
                            inputProps={startDateProps}
                            closeOnSelect
                            value={multifilters.eta_start_date}
                            renderInput={props => this.renderInput(props, 'eta_start_date')}
                            onChange={e => this.selectDate(e, 'eta_start_date', this.eta_start_date)}
                            timeFormat={false}
                            dateFormat="YYYY-MM-DD"
                          />
                          <Datetime
                            id="eta_end_date"
                            inputProps={endDateProps}
                            closeMenuOnSelect
                            value={multifilters.eta_end_date}
                            renderInput={props => this.renderInput(props, 'eta_end_date')}
                            onChange={e => this.selectDate(e, 'eta_end_date', this.eta_end_date)}
                            timeFormat={false}
                            dateFormat="YYYY-MM-DD"
                          />
                        </HStack>
                      </FormControl>
                    </Stack>
                  </MenuList>
                </Menu>
              </Box>
              {/* GRINDER */}
              <Box>
                <Menu closeOnSelect>
                  {({ isOpen, onClose }) => (
                    <>
                      <MenuButton
                        isActive={isOpen}
                        as={Button}
                        variant="outline"
                        colorScheme="gray.200"
                        color="gray.500"
                        borderColor="gray.200"
                        width="130px"
                        rightIcon={<ChevronDownIcon />}
                      >
                        <Box display="flex" alignItems="center">
                          <Box as="span" lineHeight="0">
                            Grinder
                          </Box>
                          {multifilters.grinder_uids && (
                            <Box
                              as="span"
                              width="18px"
                              height="18px"
                              marginLeft="5px"
                              borderRadius="full"
                              bgColor="#3B44B0"
                              color="white"
                              fontSize="12px"
                              lineHeight="0"
                              display="flex"
                              justifyContent="center"
                              alignItems="center"
                            >
                              1
                            </Box>
                          )}
                        </Box>
                      </MenuButton>
                      <MenuList width="361px">
                        <Stack p={6} spacing={4}>
                          {/* GRINDER */}
                          <FormControl>
                            <FormLabel htmlFor="select-grinder">Grinder</FormLabel>
                            <OrderHistorySelectInternalSelect
                              name="select-grinder"
                              defaultValue={multifilters.grinder_uids}
                              options={grinderOptions}
                              placeholder="Select"
                              onChange={e => {
                                const value = get(e, 'value', null);
                                if (value) {
                                  onClose();
                                }
                                this.handleFilterChange('grinder_uids', value);
                              }}
                            />
                          </FormControl>
                        </Stack>
                      </MenuList>
                    </>
                  )}
                </Menu>
              </Box>
              {/* PACKER */}
              <Box>
                <Menu closeOnSelect={false}>
                  <MenuButton
                    as={Button}
                    variant="outline"
                    colorScheme="gray.200"
                    color="gray.500"
                    borderColor="gray.200"
                    width="130px"
                    rightIcon={<ChevronDownIcon />}
                  >
                    <Box display="flex" alignItems="center">
                      <Box as="span" lineHeight="0">
                        Packer
                      </Box>
                      {(multifilters.packer_ids ||
                        multifilters.packer_plant_ids ||
                        multifilters.packer_so_number ||
                        multifilters.packer_invoice_number) && (
                        <Box
                          as="span"
                          width="18px"
                          height="18px"
                          marginLeft="5px"
                          borderRadius="full"
                          bgColor="#3B44B0"
                          color="white"
                          fontSize="12px"
                          lineHeight="0"
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                        >
                          {
                            [
                              multifilters.packer_ids,
                              multifilters.packer_plant_ids,
                              multifilters.packer_so_number,
                              multifilters.packer_invoice_number,
                            ].filter(Boolean).length
                          }
                        </Box>
                      )}
                    </Box>
                  </MenuButton>
                  <MenuList width="361px">
                    <Stack p={6} spacing={4}>
                      {/* PACKER */}
                      <FormControl>
                        <FormLabel htmlFor="select-establishment-group">Packer</FormLabel>
                        <OrderHistorySelectInternalSelect
                          name="select-establishment-group"
                          defaultValue={multifilters.packer_ids}
                          options={packerOptions}
                          onChange={e => {
                            this.onPackerStuffSelect('packer_ids', e ? e.value : null);
                          }}
                          placeholder="Select"
                        />
                      </FormControl>
                      {/* ESTABLISHMENT */}
                      <FormControl>
                        <FormLabel htmlFor="select-packer">Establishment</FormLabel>
                        <OrderHistorySelectInternalSelect
                          name="select-packer"
                          defaultValue={multifilters.packer_plant_ids}
                          options={individualPackerOptions}
                          onChange={e => {
                            if (e) {
                              this.onPackerStuffSelect('packer_plant_ids', e.value);
                            } else {
                              this.onPackerStuffSelect('packer_plant_ids', null);
                            }
                          }}
                          placeholder="Select"
                        />
                      </FormControl>
                      {/* PACKER SO # */}
                      <FormControl>
                        <FormLabel>Packer SO #</FormLabel>
                        <OrderHistoryInput
                          type="text"
                          value={multifilters.packer_so_number}
                          onChange={e => this.handleFilterChange('packer_so_number', get(e, 'target.value', null))}
                          placeholder="Packer SO #"
                        />
                      </FormControl>
                      {/* PACKER INVOICE # */}
                      <FormControl>
                        <FormLabel>Packer Invoice #</FormLabel>
                        <OrderHistoryInput
                          type="text"
                          value={multifilters.packer_invoice_number}
                          onChange={e => this.handleFilterChange('packer_invoice_number', get(e, 'target.value', null))}
                          placeholder="Packer Invoice #"
                        />
                      </FormControl>
                    </Stack>
                  </MenuList>
                </Menu>
              </Box>
              {/* LOGISTICS */}
              <Box>
                <Menu closeOnSelect={false}>
                  <MenuButton
                    as={Button}
                    variant="outline"
                    colorScheme="gray.200"
                    color="gray.500"
                    borderColor="gray.200"
                    // width="130px"
                    rightIcon={<ChevronDownIcon />}
                  >
                    <Box display="flex" alignItems="center">
                      <Box as="span" lineHeight="0">
                        Logistics
                      </Box>
                      {(multifilters.bol_number ||
                        multifilters.container_number ||
                        multifilters.booking_reference_number ||
                        multifilters.shipmentPortMark ||
                        multifilters.orderShipmentTypes?.length > 0 ||
                        multifilters.missing_doc_type) && (
                        <Box
                          as="span"
                          width="18px"
                          height="18px"
                          marginLeft="5px"
                          borderRadius="full"
                          bgColor="#3B44B0"
                          color="white"
                          fontSize="12px"
                          lineHeight="0"
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                        >
                          {[
                            multifilters.bol_number,
                            multifilters.container_number,
                            multifilters.booking_reference_number,
                            multifilters.shipmentPortMark,
                            multifilters.missing_doc_type,
                          ].filter(Boolean).length + multifilters.orderShipmentTypes?.length}
                        </Box>
                      )}
                    </Box>
                  </MenuButton>
                  <MenuList width="361px">
                    <Stack p={6} spacing={4}>
                      {/* BOL # */}
                      <FormControl>
                        <FormLabel htmlFor="bol_number">BOL #</FormLabel>
                        <OrderHistoryInput
                          type="text"
                          name="bol_number"
                          value={multifilters.bol_number}
                          onChange={e => this.handleFilterChange('bol_number', get(e, 'target.value', null))}
                          placeholder="BOL #"
                        />
                      </FormControl>
                      {/* CONTAINER NUMBER */}
                      <FormControl>
                        <FormLabel htmlFor="container_number">Container Number</FormLabel>
                        <OrderHistoryInput
                          type="text"
                          name="container_number"
                          value={multifilters.container_number}
                          onChange={e => this.handleFilterChange('container_number', get(e, 'target.value', null))}
                          placeholder="Container Number"
                        />
                      </FormControl>
                      {/* BOOKING REFERENCE # */}
                      <FormControl>
                        <FormLabel htmlFor="booking-reference-number">Booking Reference #</FormLabel>
                        <OrderHistoryInput
                          type="text"
                          name="booking-reference-number"
                          value={multifilters.booking_reference_number}
                          onChange={e =>
                            this.handleFilterChange('booking_reference_number', get(e, 'target.value', null))
                          }
                          placeholder="Booking Reference #"
                        />
                      </FormControl>
                      {/* SHIPMENT PORT MARK */}
                      <FormControl>
                        <FormLabel htmlFor="shipment-port">Shipment Port Mark</FormLabel>
                        <OrderHistoryInput
                          type="text"
                          name="shipment-port"
                          value={multifilters.shipmentPortMark}
                          onChange={e => this.handleFilterChange('shipmentPortMark', get(e, 'target.value', null))}
                          placeholder="Shipment Port Mark"
                        />
                      </FormControl>
                      {/* ORDER SHIPMENT TYPE */}
                      <FormControl>
                        <FormLabel htmlFor="select-order-shipment">Order Shipment Type</FormLabel>
                        <OrderHistorySelectInternalSelect
                          isMulti
                          isSearchable
                          closeMenuOnSelect
                          removeSelected
                          name="select-order-shipment"
                          defaultValue={multifilters.orderShipmentTypes}
                          options={Constants.ORDER_SHIPMENT_TYPE}
                          placeholder="Select"
                          onChange={e => this.handleFilterChange('orderShipmentTypes', e)}
                        />
                      </FormControl>
                      {/* MISSING DOCUMENT TYPE */}
                      <FormControl>
                        <FormLabel htmlFor="order-shipment-type">Missing Document Type</FormLabel>
                        <OrderHistorySelectInternalSelect
                          isSearchable
                          closeMenuOnSelect
                          removeSelected
                          name="select-missing-doc-type"
                          defaultValue={multifilters.missing_doc_type}
                          options={Constants.ORDER_MISSING_DOC_TYPE_OPTIONS}
                          placeholder="Select"
                          onChange={e => {
                            this.handleFilterChange('missing_doc_type', get(e, 'value', null));
                          }}
                        />
                      </FormControl>
                    </Stack>
                  </MenuList>
                </Menu>
              </Box>
              {/* OTHER */}
              <Box>
                <Menu closeOnSelect={false}>
                  <MenuButton
                    as={Button}
                    width="130px"
                    variant="outline"
                    colorScheme="gray.200"
                    color="gray.500"
                    borderColor="gray.200"
                    rightIcon={<ChevronDownIcon />}
                  >
                    <Box display="flex" alignItems="center">
                      <Box as="span" lineHeight="0">
                        Other
                      </Box>
                      {(multifilters.product_types?.length > 0 ||
                        multifilters.specifications?.length > 0 ||
                        multifilters.tags?.length > 0 ||
                        multifilters.buyer_id ||
                        multifilters.end_user_id ||
                        multifilters.isColdStoreRequired) && (
                        <Box
                          as="span"
                          width="18px"
                          height="18px"
                          marginLeft="5px"
                          borderRadius="full"
                          bgColor="#3B44B0"
                          color="white"
                          fontSize="12px"
                          lineHeight="0"
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                        >
                          {[multifilters.buyer_id, multifilters.end_user_id, multifilters.isColdStoreRequired].filter(
                            Boolean
                          ).length +
                            multifilters.product_types?.length +
                            multifilters.specifications?.length +
                            multifilters.tags?.length}
                        </Box>
                      )}
                    </Box>
                  </MenuButton>
                  <MenuList width="361px">
                    <Stack p={6} spacing={4}>
                      {/* PRODUCT TYPES */}
                      <FormControl>
                        <FormLabel htmlFor="select-product-types">Product Types</FormLabel>
                        <OrderHistorySelectInternalSelect
                          isMulti
                          isSearchable
                          closeMenuOnSelect
                          removeSelected
                          name="select-product-types"
                          defaultValue={multifilters.product_types}
                          options={Constants.PRODUCT_TYPES}
                          placeholder="Select"
                          onChange={e => this.handleFilterChange('product_types', e)}
                        />
                      </FormControl>
                      {/* SPECIFICATIONS */}
                      <FormControl>
                        <FormLabel htmlFor="select-specifications">Specifications</FormLabel>
                        <OrderHistorySelectInternalSelect
                          isMulti
                          isSearchable
                          closeOnSelect
                          removeSelected
                          name="select-specifications"
                          defaultValue={multifilters.specifications}
                          options={specificationOptions}
                          onChange={e => this.handleFilterChange('specifications', e)}
                          placeholder="Select"
                        />
                      </FormControl>
                      {/* TAGS */}
                      <FormControl>
                        <FormLabel htmlFor="select-tags">Tags</FormLabel>
                        <OrderHistorySelectInternalSelect
                          isMulti
                          isSearchable
                          closeOnSelect
                          removeSelected
                          name="select-tags"
                          defaultValue={multifilters.tags}
                          options={labelOptions}
                          onChange={e => this.handleFilterChange('tags', e)}
                          placeholder="Select"
                        />
                      </FormControl>
                      {/* BUYER */}
                      <FormControl>
                        <FormLabel htmlFor="select-buyer">Buyer</FormLabel>
                        <OrderHistorySelectInternalSelect
                          name="select-buyer"
                          defaultValue={multifilters.buyer_id}
                          options={buyerOptions}
                          onChange={e => this.onBuyerSelect(e ? e.value : null)}
                          placeholder="Select"
                        />
                      </FormControl>
                      {/* END USER */}
                      <FormControl>
                        <FormLabel htmlFor="coldstore-required">End User</FormLabel>
                        <OrderHistorySelectInternalSelect
                          isSearchable
                          name="select-end-user"
                          defaultValue={multifilters.end_user_id}
                          options={endUserOptions}
                          placeholder="Select"
                          onChange={e => {
                            this.onEndUserSelect(get(e, 'value', null));
                          }}
                        />
                      </FormControl>
                      <FormControl>
                        <FormLabel htmlFor="coldstore-required">Coldstore Required</FormLabel>
                        <OrderHistorySelectInternalSelect
                          isSearchable
                          closeMenuOnSelect
                          removeSelected
                          name="select-coldstore-required"
                          defaultValue={multifilters.isColdStoreRequired}
                          options={Constants.TRUE_FALSE_OPTIONS}
                          placeholder="Select"
                          onChange={e => {
                            this.handleFilterChange('isColdStoreRequired', get(e, 'value', null));
                          }}
                        />
                      </FormControl>
                    </Stack>
                  </MenuList>
                </Menu>
              </Box>
              {/* RESET */}
              <Button variant="ghost" width="73px" height="43px" onClick={this.resetSearch}>
                <Text as="p" fontSize="16px">
                  Reset
                </Text>
              </Button>
              {/* FILTER */}
              <Button colorScheme="blue" width="140px" type="submit">
                Filter
              </Button>
            </HStack>
          </HStack>

          {this.getSelectedFilters().length > 0 && (
            <Box my={6} display="flex" justifyContent="flex-end">
              <Text as="p" fontSize="15px" fontWeight="bold" marginRight="10px" padding="5px 0" flexShrink="0">
                <Text as="b" color="#292F71">
                  Filters applied:
                </Text>
              </Text>

              <Box
                display="flex"
                gap="10px"
                marginRight="10px"
                flexWrap="wrap"
                height={showMoreFilters ? 'auto' : '32px'}
                overflow={showMoreFilters ? 'auto' : 'hidden'}
              >
                {this.getSelectedFilters().map((data, index) =>
                  Array.isArray(data.value) ? (
                    data.value.map((value, i) => (
                      <Box
                        key={i}
                        display="flex"
                        alignItems="center"
                        padding="5px 10px"
                        bgColor="#EBF0FF"
                        color="#292F71"
                        fontSize="14px"
                        borderRadius="6px"
                        marginRight="10px"
                      >
                        <Text as="b" fontSize="15px" fontWeight="bold" marginRight="5px">
                          {data.label}:
                        </Text>
                        <Text as="span" fontSize="15px" marginRight="10px" fontWeight="normal">
                          {data.label === 'Status' ? orderStatusMap[value] : value?.label || value}
                        </Text>
                        <button
                          type="button"
                          onClick={() =>
                            this.removeFilter(
                              data.key,
                              multifilters[data.key].filter(v => v !== value)
                            )
                          }
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="10"
                            height="10"
                            viewBox="0 0 10 10"
                            fill="none"
                          >
                            <path
                              d="M5 3.88906L8.88906 0L10 1.11094L6.11094 5L10 8.88906L8.88906 10L5 6.11094L1.11094 10L0 8.88906L3.88906 5L0 1.11094L1.11094 0L5 3.88906Z"
                              fill="#1A202C"
                            />
                          </svg>
                        </button>
                      </Box>
                    ))
                  ) : (
                    <Box
                      key={index}
                      display="flex"
                      alignItems="center"
                      padding="5px 10px"
                      bgColor="#EBF0FF"
                      color="#292F71"
                      fontSize="14px"
                      borderRadius="6px"
                      marginRight="10px"
                    >
                      <Text as="b" fontSize="15px" fontWeight="bold" marginRight="5px">
                        {data.label}:
                      </Text>
                      <Text as="span" fontSize="15px" marginRight="10px" fontWeight="normal">
                        {this.getLabelWithValueType(data.key, data.value)}
                      </Text>
                      <button type="button" onClick={() => this.removeFilter(data.key, null)}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
                          <path
                            d="M5 3.88906L8.88906 0L10 1.11094L6.11094 5L10 8.88906L8.88906 10L5 6.11094L1.11094 10L0 8.88906L3.88906 5L0 1.11094L1.11094 0L5 3.88906Z"
                            fill="#1A202C"
                          />
                        </svg>
                      </button>
                    </Box>
                  )
                )}
              </Box>

              <button
                type="button"
                style={{
                  flexShrink: '0',
                  padding: '2px 8px',
                  borderRadius: '6px',
                  marginRight: '10px',
                  marginTop: '3px',
                  alignSelf: 'start',
                }}
              >
                <Text
                  display="flex"
                  alignItems="center"
                  as="p"
                  fontSize="15px"
                  fontWeight="bold"
                  whiteSpace="nowrap"
                  color="#3A44B0"
                  onClick={() => this.setState({ showMoreFilters: !showMoreFilters })}
                >
                  <Text as="span" marginRight="5px">
                    View {showMoreFilters ? 'Less' : 'More'}
                  </Text>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="8"
                    viewBox="0 0 12 8"
                    fill="none"
                    style={{ rotate: showMoreFilters ? '180deg' : '0deg' }}
                  >
                    <path
                      d="M2 2L6 6L10 2"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeMiterlimit="10"
                      strokeLinecap="square"
                    />
                  </svg>
                </Text>
              </button>

              <button
                type="button"
                style={{
                  flexShrink: '0',
                  padding: '2px 8px',
                  backgroundColor: '#FFF9F5',
                  marginTop: '3px',
                  borderRadius: '6px',
                  alignSelf: 'start',
                }}
              >
                <Text
                  display="flex"
                  alignItems="center"
                  as="p"
                  fontSize="15px"
                  fontWeight="bold"
                  whiteSpace="nowrap"
                  color="#B03A3A"
                  onClick={this.resetSearch}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
                    <path
                      d="M5 3.88906L8.88906 0L10 1.11094L6.11094 5L10 8.88906L8.88906 10L5 6.11094L1.11094 10L0 8.88906L3.88906 5L0 1.11094L1.11094 0L5 3.88906Z"
                      fill="currentColor"
                    />
                  </svg>
                  <Text as="span" marginLeft="5px">
                    Clear Filters
                  </Text>
                </Text>
              </button>
            </Box>
          )}

          <div className="result-container">
            {loading && (
              <Flex justifyContent="center" mt={4}>
                <Image src="img/loader.gif" alt="loader" />
              </Flex>
            )}

            {!loading && this.props.order_history.length > 0 ? (
              <Box width="100%" overflowX="auto">
                <OrderTable
                  date_select={false}
                  setLoading={this.setLoading}
                  current_orders={this.props.order_history}
                  orderStatusMap={orderStatusMap}
                  {...this.props}
                />
                <HStack pt="2em">
                  <Button
                    width="207px"
                    height="43px"
                    isDisabled={this.props.order_history.length < 1}
                    onClick={this.exportOrders.bind(this)}
                    rightIcon={<ShareOutlineSVG />}
                    isLoading={isExporting}
                    loadingText="Exporting..."
                  >
                    <Text as="p" fontSize="md">
                      Export Listed Orders
                    </Text>
                  </Button>
                  <PaginationBar
                    currentPage={
                      orderPagination.has_next ? orderPagination?.next_page - 1 || 1 : orderPagination?.pages
                    }
                    pages={orderPagination?.pages || 10}
                    onPageChange={page => {
                      this.handlePageChange({
                        page,
                      });
                    }}
                    justifyContent="flex-end"
                    marginY={6}
                    paddingX={5}
                  />
                </HStack>
              </Box>
            ) : (
              <div>
                <b>Enter your search filters and click the &quot;Filter&quot; button to get results.</b>
              </div>
            )}
          </div>
        </Box>
        {/* 
        LEGACY ORDER MANAGEMENT SEARCH
        <Center>
          <VStack spacing="26px" align="stretch">
            <Text as="p" fontSize="18px" fontWeight="bold" w="100%">
              Order History
            </Text>
            <DashboardSectionTile title="Search Filters" useDefaultWidth>
              <Wrap spacing="29px">
                <Box width="90px">
                  <Text as="p" fontSize="15px" fontWeight="bold">
                    Quick Filters:
                  </Text>
                </Box>
                <ButtonGroup colorScheme="geekBlue" variant="outline">
                  <Button
                    onClick={() =>
                      this.handleFilterChange(
                        'ordered_start_date',
                        moment().subtract(24, 'hours').format('YYYY-MM-DD'),
                        'instasearch'
                      )
                    }
                  >
                    <Text as="p" fontSize="sm">
                      Created Last 24 hours
                    </Text>
                  </Button>
                  <Button
                    onClick={() =>
                      this.handleDateRangeFilterChange(
                        {
                          delivery_start_date: moment().subtract(7, 'days').format('YYYY-MM-DD'),
                          delivery_end_date: moment().format('YYYY-MM-DD'),
                        },
                        'instasearch'
                      )
                    }
                  >
                    <Text as="p" fontSize="sm">
                      Delivered Last Week
                    </Text>
                  </Button>
                  <Button
                    onClick={() =>
                      this.handleDateRangeFilterChange(
                        {
                          delivery_start_date: moment().format('YYYY-MM-DD'),
                          delivery_end_date: moment().add(7, 'days').format('YYYY-MM-DD'),
                        },
                        'instasearch'
                      )
                    }
                  >
                    <Text as="p" fontSize="sm">
                      Delivered Next Week
                    </Text>
                  </Button>
                  <Button
                    onClick={() =>
                      this.handleDateRangeFilterChange(
                        {
                          delivery_start_date: moment().format('YYYY-MM-DD'),
                          delivery_end_date: moment().add(14, 'days').format('YYYY-MM-DD'),
                        },
                        'instasearch'
                      )
                    }
                  >
                    <Text as="p" fontSize="sm">
                      Delivered Next Two Weeks
                    </Text>
                  </Button>
                </ButtonGroup>
              </Wrap>
              <HStack spacing="29px">
                <Box width="90px">
                  <Text as="p" fontSize="15px" fontWeight="bold">
                    Multi Filters:
                  </Text>
                </Box>
                <VStack spacing="23px" align="flex-start">
                  <HStack spacing="64px">
                    <Box width="47px">
                      <Text as="p" fontSize="15px" fontWeight="normal">
                        User:
                      </Text>
                    </Box>
                    <CheckboxGroupContainer>
                      <Checkbox
                        fontWeight="bold"
                        mb={0}
                        onChange={() => this.handleFilterChange('user_id', this.props.user.id)}
                        isChecked={!isEmpty(multifilters.user_id)}
                      >
                        My Orders
                      </Checkbox>
                    </CheckboxGroupContainer>
                  </HStack>
                  <HStack spacing="64px">
                    <Box width="47px">
                      <Text as="p" fontSize="15px" fontWeight="normal">
                        Status:
                      </Text>
                    </Box>
                    <CheckboxGroupContainer>
                      <Checkbox
                        fontWeight="bold"
                        mb={0}
                        onChange={() => this.handleFilterChange('status', 'ordered')}
                        isChecked={multifilters.status.indexOf('ordered') > -1}
                      >
                        {orderStatusMap['ordered']}
                      </Checkbox>
                      <Checkbox
                        fontWeight="bold"
                        mb={0}
                        onChange={() => this.handleFilterChange('status', 'coldstore')}
                        isChecked={multifilters.status.indexOf('coldstore') > -1}
                      >
                        {orderStatusMap['coldstore']}
                      </Checkbox>
                      <Checkbox
                        fontWeight="bold"
                        mb={0}
                        onChange={() => this.handleFilterChange('status', 'pending_coldstore_schedule')}
                        isChecked={multifilters.status.indexOf('pending_coldstore_schedule') > -1}
                      >
                        {orderStatusMap['pending_coldstore_schedule']}
                      </Checkbox>
                      <Checkbox
                        fontWeight="bold"
                        mb={0}
                        onChange={() => this.handleFilterChange('status', 'coldstore_released')}
                        isChecked={multifilters.status.indexOf('coldstore_released') > -1}
                      >
                        {orderStatusMap['coldstore_released']}
                      </Checkbox>
                      <Checkbox
                        fontWeight="bold"
                        mb={0}
                        onChange={() => this.handleFilterChange('status', 'received')}
                        isChecked={multifilters.status.indexOf('received') > -1}
                      >
                        {orderStatusMap['received']}
                      </Checkbox>
                      <Checkbox
                        fontWeight="bold"
                        mb={0}
                        onChange={() => this.handleFilterChange('status', 'pending')}
                        isChecked={multifilters.status.indexOf('pending') > -1}
                      >
                        {orderStatusMap['pending']}
                      </Checkbox>
                      <Checkbox
                        fontWeight="bold"
                        mb={0}
                        onChange={() => this.handleFilterChange('status', 'cancelled')}
                        isChecked={multifilters.status.indexOf('cancelled') > -1}
                      >
                        {orderStatusMap['cancelled']}
                      </Checkbox>
                    </CheckboxGroupContainer>
                  </HStack>
                  <HStack spacing="64px">
                    <Box width="47px">
                      <Text as="p" fontSize="15px" fontWeight="normal">
                        Other:
                      </Text>
                    </Box>
                    <CheckboxGroupContainer>
                      <Checkbox
                        fontWeight="bold"
                        mb={0}
                        onChange={() =>
                          this.handleFilterChange(
                            'exclude_delisted_packers',
                            !multifilters.exclude_delisted_packers
                          )
                        }
                        isChecked={multifilters.exclude_delisted_packers}
                      >
                        Exclude Delisted Establishments
                      </Checkbox>
                      <Checkbox
                        fontWeight="bold"
                        mb={0}
                        onChange={() => this.handleFilterChange('noEtd', !multifilters.noEtd)}
                        isChecked={multifilters.noEtd}
                      >
                        No ETD
                      </Checkbox>
                      <Checkbox
                        fontWeight="bold"
                        mb={0}
                        onChange={() => this.handleFilterChange('partial_search', !multifilters.partial_search)}
                        isChecked={multifilters.partial_search}
                      >
                        Partial Search
                      </Checkbox>
                    </CheckboxGroupContainer>
                  </HStack>
                </VStack>
              </HStack>
              <Wrap spacing="29px" mt="56px !important" justify="space-between">
                <CustomFormControl>
                  <FormLabel>Date Created</FormLabel>
                  <HStack>
                    <Datetime
                      id="ordered_start_date"
                      inputProps={startDateProps}
                      closeOnSelect
                      value={multifilters.ordered_start_date}
                      renderInput={props => this.renderInput(props, 'orderedStartRef')}
                      onChange={e => this.selectDate(e, 'ordered_start_date', this.orderedStartRef)}
                      timeFormat={false}
                      dateFormat="YYYY-MM-DD"
                    />
                    <Datetime
                      id="ordered_end_date"
                      inputProps={endDateProps}
                      closeOnSelect
                      value={multifilters.ordered_end_date}
                      renderInput={props => this.renderInput(props, 'orderedEndRef')}
                      onChange={e => this.selectDate(e, 'ordered_end_date', this.orderedEndRef)}
                      timeFormat={false}
                      dateFormat="YYYY-MM-DD"
                    />
                  </HStack>
                </CustomFormControl>
                <CustomFormControl>
                  <FormLabel>Date Delivery</FormLabel>
                  <HStack>
                    <Datetime
                      id="delivery_start_date"
                      inputProps={startDateProps}
                      closeOnSelect
                      value={multifilters.delivery_start_date}
                      renderInput={props => this.renderInput(props, 'deliveryStartRef')}
                      onChange={e => this.selectDate(e, 'delivery_start_date', this.deliveryStartRef)}
                      timeFormat={false}
                      dateFormat="YYYY-MM-DD"
                    />
                    <Datetime
                      id="delivery_end_date"
                      inputProps={endDateProps}
                      closeOnSelect
                      value={multifilters.delivery_end_date}
                      renderInput={props => this.renderInput(props, 'deliveryEndRef')}
                      onChange={e => this.selectDate(e, 'delivery_end_date', this.deliveryEndRef)}
                      timeFormat={false}
                      dateFormat="YYYY-MM-DD"
                    />
                  </HStack>
                </CustomFormControl>
                <CustomFormControl>
                  <FormLabel htmlFor="select-grinder">Grinder</FormLabel>
                  <OrderHistorySelectInternalSelect
                    name="select-grinder"
                    defaultValue={multifilters.grinder_uids}
                    options={grinderOptions}
                    placeholder="Select"
                    onChange={e => {
                      this.handleFilterChange('grinder_uids', get(e, 'value', null));
                    }}
                  />
                </CustomFormControl>
                <CustomFormControl>
                  <FormLabel htmlFor="grinder-po-number-search">Grinder PO #</FormLabel>
                  <OrderHistoryInput
                    h={10}
                    type="text"
                    value={multifilters.grinder_po_number}
                    onChange={e => this.handleFilterChange('grinder_po_number', get(e, 'target.value', null))}
                    placeholder="Grinder PO #"
                  />
                </CustomFormControl>
                <CustomFormControl>
                  <FormLabel>{internalPOLabel}</FormLabel>
                  <OrderHistoryInput
                    type="number"
                    value={multifilters.internal_po_number}
                    onChange={e => this.handleFilterChange('internal_po_number', get(e, 'target.value', null))}
                    onWheelCapture={e => {
                      e.target.blur();
                    }}
                    placeholder={internalPOLabel}
                  />
                </CustomFormControl>
                <CustomFormControl>
                  <FormLabel>Packer SO #</FormLabel>
                  <OrderHistoryInput
                    type="text"
                    value={multifilters.packer_so_number}
                    onChange={e => this.handleFilterChange('packer_so_number', get(e, 'target.value', null))}
                    placeholder="Packer SO #"
                  />
                </CustomFormControl>
                <CustomFormControl>
                  <FormLabel>Packer Invoice #</FormLabel>
                  <OrderHistoryInput
                    type="text"
                    value={multifilters.packer_invoice_number}
                    onChange={e => this.handleFilterChange('packer_invoice_number', get(e, 'target.value', null))}
                    placeholder="Packer Invoice #"
                  />
                </CustomFormControl>
                <CustomFormControl>
                  <FormLabel htmlFor="select-packer">Establishment</FormLabel>
                  <OrderHistorySelectInternalSelect
                    name="select-packer"
                    defaultValue={multifilters.packer_plant_ids}
                    options={individualPackerOptions}
                    onChange={e => {
                      if (e) {
                        this.onPackerStuffSelect('packer_plant_ids', e.value);
                      } else {
                        this.onPackerStuffSelect('packer_plant_ids', null);
                      }
                    }}
                    placeholder="Select"
                  />
                </CustomFormControl>
                <CustomFormControl>
                  <FormLabel htmlFor="select-establishment-group">Packer</FormLabel>
                  <OrderHistorySelectInternalSelect
                    name="select-establishment-group"
                    defaultValue={multifilters.packer_ids}
                    options={packerOptions}
                    onChange={e => {
                      this.onPackerStuffSelect('packer_ids', e ? e.value : null);
                    }}
                    placeholder="Select"
                  />
                </CustomFormControl>
                <CustomFormControl>
                  <FormLabel htmlFor="select-buyer">Buyer</FormLabel>
                  <OrderHistorySelectInternalSelect
                    name="select-buyer"
                    defaultValue={multifilters.buyer_id}
                    options={buyerOptions}
                    onChange={e => this.onBuyerSelect(e ? e.value : null)}
                    placeholder="Select"
                  />
                </CustomFormControl>
                <CustomFormControl>
                  <FormLabel htmlFor="bol_number">BOL #</FormLabel>
                  <OrderHistoryInput
                    type="text"
                    name="bol_number"
                    value={multifilters.bol_number}
                    onChange={e => this.handleFilterChange('bol_number', get(e, 'target.value', null))}
                    placeholder="BOL #"
                  />
                </CustomFormControl>
                <CustomFormControl>
                  <FormLabel htmlFor="container_number">Container Number</FormLabel>
                  <OrderHistoryInput
                    type="text"
                    name="container_number"
                    value={multifilters.container_number}
                    onChange={e => this.handleFilterChange('container_number', get(e, 'target.value', null))}
                    placeholder="Container Number"
                  />
                </CustomFormControl>
                <CustomFormControl>
                  <FormLabel htmlFor="booking-reference-number">Booking Reference #</FormLabel>
                  <OrderHistoryInput
                    type="text"
                    name="booking-reference-number"
                    value={multifilters.booking_reference_number}
                    onChange={e => this.handleFilterChange('booking_reference_number', get(e, 'target.value', null))}
                    placeholder="Booking Reference #"
                  />
                </CustomFormControl>
                <CustomFormControl>
                  <FormLabel htmlFor="select-specifications">Specifications</FormLabel>
                  <OrderHistorySelectInternalSelect
                    isMulti
                    isSearchable
                    closeOnSelect
                    removeSelected
                    name="select-specifications"
                    defaultValue={multifilters.specifications}
                    options={specificationOptions}
                    onChange={e => this.handleFilterChange('specifications', e)}
                    placeholder="Select"
                  />
                </CustomFormControl>
                <CustomFormControl>
                  <FormLabel htmlFor="select-tags">Tags</FormLabel>
                  <OrderHistorySelectInternalSelect
                    isMulti
                    isSearchable
                    closeOnSelect
                    removeSelected
                    name="select-tags"
                    defaultValue={multifilters.tags}
                    options={labelOptions}
                    onChange={e => this.handleFilterChange('tags', e)}
                    placeholder="Select"
                  />
                </CustomFormControl>
                <CustomFormControl>
                  <FormLabel htmlFor="select-product-types">Product Types</FormLabel>
                  <OrderHistorySelectInternalSelect
                    isMulti
                    isSearchable
                    closeMenuOnSelect
                    removeSelected
                    name="select-product-types"
                    defaultValue={multifilters.product_types}
                    options={Constants.PRODUCT_TYPES}
                    placeholder="Select"
                    onChange={e => this.handleFilterChange('product_types', e)}
                  />
                </CustomFormControl>
                <CustomFormControl>
                  <FormLabel htmlFor="etd-start-end">ETD Start/End</FormLabel>
                  <HStack>
                    <Datetime
                      id="etd_start_date"
                      inputProps={startDateProps}
                      closeOnSelect
                      value={multifilters.etd_start_date}
                      renderInput={props => this.renderInput(props, 'etd_start_date')}
                      onChange={e => this.selectDate(e, 'etd_start_date', this.etd_start_date)}
                      timeFormat={false}
                      dateFormat="YYYY-MM-DD"
                    />
                    <Datetime
                      id="etd_end_date"
                      inputProps={endDateProps}
                      closeMenuOnSelect
                      value={multifilters.etd_end_date}
                      renderInput={props => this.renderInput(props, 'etd_end_date')}
                      onChange={e => this.selectDate(e, 'etd_end_date', this.etd_end_date)}
                      timeFormat={false}
                      dateFormat="YYYY-MM-DD"
                    />
                  </HStack>
                </CustomFormControl>
                <CustomFormControl>
                  <FormLabel htmlFor="select-order-shipment">Order Shipment Type</FormLabel>
                  <OrderHistorySelectInternalSelect
                    isMulti
                    isSearchable
                    closeMenuOnSelect
                    removeSelected
                    name="select-order-shipment"
                    defaultValue={multifilters.orderShipmentTypes}
                    options={Constants.ORDER_SHIPMENT_TYPE}
                    placeholder="Select"
                    onChange={e => this.handleFilterChange('orderShipmentTypes', e)}
                  />
                </CustomFormControl>
                <CustomFormControl>
                  <FormLabel htmlFor="order-shipment-type">Missing Document Type</FormLabel>
                  <OrderHistorySelectInternalSelect
                    isSearchable
                    closeMenuOnSelect
                    removeSelected
                    name="select-missing-doc-type"
                    defaultValue={multifilters.missing_doc_type}
                    options={Constants.ORDER_MISSING_DOC_TYPE_OPTIONS}
                    placeholder="Select"
                    onChange={e => {
                      this.handleFilterChange('missing_doc_type', get(e, 'value', null));
                    }}
                  />
                </CustomFormControl>
                <CustomFormControl>
                  <FormLabel htmlFor="shipment-port">Shipment Port Mark</FormLabel>
                  <OrderHistoryInput
                    type="text"
                    name="shipment-port"
                    value={multifilters.shipmentPortMark}
                    onChange={e => this.handleFilterChange('shipmentPortMark', get(e, 'target.value', null))}
                    placeholder="Shipment Port Mark"
                  />
                </CustomFormControl>
                <CustomFormControl>
                  <FormLabel htmlFor="coldstore-required">Coldstore Required</FormLabel>
                  <OrderHistorySelectInternalSelect
                    isSearchable
                    closeMenuOnSelect
                    removeSelected
                    name="select-coldstore-required"
                    defaultValue={multifilters.isColdStoreRequired}
                    options={Constants.TRUE_FALSE_OPTIONS}
                    placeholder="Select"
                    onChange={e => {
                      this.handleFilterChange('isColdStoreRequired', get(e, 'value', null));
                    }}
                  />
                </CustomFormControl>
                <CustomFormControl
                  marginRight="auto !important"
                  marginLeft={{ base: '224px !important', xl: '25px !important', '2xl': '68px !important' }}
                >
                  <FormLabel htmlFor="coldstore-required">End User</FormLabel>
                  <OrderHistorySelectInternalSelect
                    isSearchable
                    name="select-end-user"
                    defaultValue={multifilters.end_user_id}
                    options={endUserOptions}
                    placeholder="Select"
                    onChange={e => {
                      this.onEndUserSelect(get(e, 'value', null));
                    }}
                  />
                </CustomFormControl>
              </Wrap>
              <HStack spacing="20px" marginTop="31px !important">
                <HStack spacing="10px">
                  <Box bg="#FFBFBF" h="18px" w="18px" borderRadius="18px" />
                  <Text as="p">Cancelled Order</Text>
                </HStack>
                <HStack spacing="10px">
                  <Box bg="#FCECBB" h="18px" w="18px" borderRadius="18px" />
                  <Text as="p">No Expected Production Date</Text>
                </HStack>
              </HStack>
              <Flex marginTop="50px !important">
                <Button
                  width="207px"
                  height="43px"
                  isDisabled={this.props.order_history.length < 1}
                  onClick={this.exportOrders.bind(this)}
                  rightIcon={<ShareOutlineSVG />}
                  isLoading={isExporting}
                  loadingText="Exporting..."
                >
                  <Text as="p" fontSize="md">
                    Export Listed Orders
                  </Text>
                </Button>
                <ButtonGroup spacing="2px" ml="auto" colorScheme="actionSecondary">
                  <Button variant="ghost" width="73px" height="43px" onClick={this.resetSearch}>
                    <Text as="p" fontSize="16px">
                      Reset
                    </Text>
                  </Button>
                  <Button
                    variant="solid"
                    width="166px"
                    height="43px"
                    // onClick={this.submitSearch}
                    type="submit"
                  >
                    <Text as="p" fontSize="16px">
                      Submit Search
                    </Text>
                  </Button>
                </ButtonGroup>
              </Flex>
              {loading ? (
                <Flex justifyContent="center" mt={4}>
                  <Image src="img/loader.gif" alt="loader" />
                </Flex>
              ) : (
                this.props.order_history.length && (
                  <Box width="100%" overflowX="scroll">
                    <OrderTable
                      date_select={false}
                      setLoading={this.setLoading}
                      current_orders={this.props.order_history}
                      orderStatusMap={orderStatusMap}
                      {...this.props}
                    />
                    <PaginationBar
                      currentPage={
                        orderPagination.has_next ? orderPagination?.next_page - 1 || 1 : orderPagination?.pages
                      }
                      pages={orderPagination?.pages || 10}
                      onPageChange={page => {
                        this.handlePageChange({
                          page,
                        });
                      }}
                      justifyContent="flex-end"
                      marginY={6}
                      paddingX={5}
                    />
                  </Box>
                )
              )}
            </DashboardSectionTile>
          </VStack>
        </Center> */}
      </form>
    );
  }
}
const mapStateToProps = state => ({
  orderStatusMap: selectOrderStatusMap(state),
});

const mapDispatchToProps = dispatch => ({
  fetchOrderStatusMap: () => dispatch(fetchOrderStatusMap()),
});

export default connect(mapStateToProps, mapDispatchToProps)(OrderHistory);
// export default OrderHistory;
